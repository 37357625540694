import { Notification } from '../@types/views';

export default class ViewStore {
  notifications: Notification[] = [];
  enqueueSnackbar(noti: Notification) {
    this.notifications.push({
      key: +new Date(),
      ...noti,
    });
  }
  removeSnackbar(key: number) {
    this.notifications = this.notifications.filter(
      notification => notification.key !== key
    );
  }
}
