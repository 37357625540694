import { Suspense, lazy, ElementType } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
// layouts
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
import HeaderOnlyLayout from '../layouts/headerOnly';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';

// components
import LoadingScreen from '../components/LoadingScreen';
import EmailSent from '../pages/auth/EmailSent';

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { isAuthenticated } = useAuth();

  const isDashboard = pathname.includes('/dashboard') && isAuthenticated;

  return (
    <Suspense fallback={<LoadingScreen isDashboard={isDashboard} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    // Auth Routes
    {
      path: 'terms',
      element: (
        <Terms />
      ),
    },
    {
      path: 'privacy',
      element: (
        <Privacy />
      ),
    },
    {
      path: 'faqs',
      element: (
        <Faqs />
      ),
    },
    {
      path: 'login',
      element: (
        <GuestGuard>
          <Login />
        </GuestGuard>
      ),
    },
    { path: 'email-sent', element: <EmailSent /> },
    {
      path: 'auth/:mode',
      element: (
        <GuestGuard>
          <TokenLogin />
        </GuestGuard>
      ),
    },

    // User Routes
    {
      path: 'user',
      element: 
        <AuthGuard><HeaderOnlyLayout /></AuthGuard>,
      children: [
        { path: 'detector', element: <Detector /> },
        { path: 'result', element: <DetectionResult /> },
      ]
    },

  {
    path: '/',
    element: (
      <GuestGuard>
        <Login />
      </GuestGuard>
    )
  },
    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'maintenance', element: <Maintenance /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
        { path: 'token-error', element: <TokenError /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const TokenLogin = Loadable(lazy(() => import('../pages/auth/TokenVerify')));
const Terms = Loadable(lazy(() => import('../pages/auth/Terms')));
const Privacy = Loadable(lazy(() => import('../pages/auth/Privacy')));

const Faqs = Loadable(lazy(() => import('../pages/auth/Faqs')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const Page403 = Loadable(lazy(() => import('../pages/Page403')));
const Page404 = Loadable(lazy(() => import('../pages/Page404')));
const TokenError = Loadable(lazy(() => import('../pages/TokenError')));


// USER
const Detector = Loadable(lazy(() => import('../pages/user/Detector')));
const DetectionResult = Loadable(lazy(() => import('../pages/user/DetectionResult')));