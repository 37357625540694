//config
import { CreateAxiosDefaults } from 'axios';

// MINIMAL

// @mui
import { 
  enUS, 
  // zhHK, frFR, zhCN, viVN, arSD 
} from '@mui/material/locale';
// components
import { SettingsValueProps } from './components/settings/type';
// routes
import { LOGIN, REGISTER } from './constants/requests';
import { SERVER_ADDRESS, FAST_API_ADDRESS } from 'awd-common/lib/config'
//others
import palette from './theme/palette';

export const SERVER_AXIOS_CONFIGS: CreateAxiosDefaults = {
  baseURL: SERVER_ADDRESS,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
};

export const ANALYSIS_AXIOS_CONFIGS: CreateAxiosDefaults = {
  baseURL: FAST_API_ADDRESS,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
};

// AWD

/**
 * If error of these req are handled by the FE (e.g. form), then do NOT display error snackbar
 */
export const AXIOS_NO_ERROR_BAR_REQS = new Set([
  LOGIN.url,
  REGISTER.url,
])

// MINIMAL
// API
// ----------------------------------------------------------------------

export const HOST_API = process.env.REACT_APP_HOST_API_KEY || '';

export const FIREBASE_API = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export const COGNITO_API = {
  userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
  clientId: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID,
};

export const AUTH0_API = {
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
};

export const MAPBOX_API = process.env.REACT_APP_MAPBOX_API;


// Env variable
export const MS_SPEECH_REGION = process.env.REACT_APP_MS_SPEECH_REGION;

// LAYOUT
// ----------------------------------------------------------------------

export const HEADER = {
  MOBILE_HEIGHT: 64,
  MAIN_DESKTOP_HEIGHT: 88,
  DASHBOARD_DESKTOP_HEIGHT: 92,
  DASHBOARD_DESKTOP_OFFSET_HEIGHT: 92 - 32,
};

export const NAVBAR = {
  BASE_WIDTH: 260,
  DASHBOARD_WIDTH: 280,
  DASHBOARD_COLLAPSE_WIDTH: 88,
  //
  DASHBOARD_ITEM_ROOT_HEIGHT: 48,
  DASHBOARD_ITEM_SUB_HEIGHT: 40,
  DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32,
};

export const ICON = {
  NAVBAR_ITEM: 22,
  NAVBAR_ITEM_HORIZONTAL: 20,
};

// SETTINGS
// Please remove `localStorage` when you change settings.
// ----------------------------------------------------------------------

export const defaultSettings: SettingsValueProps = {
  themeMode: 'light',
  themeDirection: 'ltr',
  themeContrast: 'default',
  themeLayout: 'horizontal',
  themeColorPresets: 'default',
  themeStretch: false,
};

// MULTI LANGUAGES
// Please remove `localStorage` when you change settings.
// ----------------------------------------------------------------------

export const allLangs = [
  {
    label: 'English',
    value: 'en',
    systemValue: enUS,
    icon: '/assets/icons/flags/ic_flag_en.svg',
  },
  // cadmann : customized
  //
  // {
  //   label: 'Chinese (HK)',
  //   value: 'zhHK',
  //   systemValue: zhHK,
  //   icon: '/assets/icons/flags/ic_flag_hk.svg',
  // },
  // {
  //   label: 'French',
  //   value: 'fr',
  //   systemValue: frFR,
  //   icon: '/assets/icons/flags/ic_flag_fr.svg',
  // },
  // {
  //   label: 'Vietnamese',
  //   value: 'vn',
  //   systemValue: viVN,
  //   icon: '/assets/icons/flags/ic_flag_vn.svg',
  // },
  // {
  //   label: 'Chinese',
  //   value: 'cn',
  //   systemValue: zhCN,
  //   icon: '/assets/icons/flags/ic_flag_cn.svg',
  // },
  // {
  //   label: 'Arabic (Sudan)',
  //   value: 'ar',
  //   systemValue: arSD,
  //   icon: '/assets/icons/flags/ic_flag_sa.svg',
  // },
];

export const defaultLang = allLangs[0]; // English

export const LANG_OPTIONS = [
  { label: 'English (Australia)', value: 'en-AU' },
  { label: 'English (Canada)', value: 'en-CA' },
  { label: 'English (United Kingdom)', value: 'en-GB' },
  { label: 'English (Ghana)', value: 'en-GH' },
  { label: 'English (Hong Kong SAR)', value: 'en-HK' },
  { label: 'English (Ireland)', value: 'en-IE' },
  { label: 'English (India)', value: 'en-IN' },
  { label: 'English (Kenya)', value: 'en-KE' },
  { label: 'English (Nigeria)', value: 'en-NG' },
  { label: 'English (New Zealand)', value: 'en-NZ' },
  { label: 'English (Philippines)', value: 'en-PH' },
  { label: 'English (Singapore)', value: 'en-SG' },
  { label: 'English (Tanzania)', value: 'en-TZ' },
  { label: 'English (United States)', value: 'en-US' },
  { label: 'English (South Africa)', value: 'en-ZA' },
];

export const DEFAULT_LANG = 'en-HK';

export const mapCaptionText = {
  score_answer_length: "answer length",
  score_suggested_keyword: "suggested keyword",
  score_text_sentiment: "text sentiment",
  score_grammar: "grammar",
  score_speed: "speed",
  score_smoothness: "smoothness",
  score_slang: "slang",
  score_text_stt_edit: "answer editing",
  score_question_listen: "question replay",
  score_idle_before_answer: "organising time",
  score_head: "head",
  score_eye: "eyes",
  score_body: "body",
  score_accessory: "accessories",
  score_emotion: "emotions",
  score_content: "total",
  score_speaking: "total",
  score_listening: "total",
  score_presentation: "total",
};

export const contentKeys = [
  "score_answer_length",
  "score_suggested_keyword",
  "score_text_sentiment",
  "score_grammar",
  "score_content",
]

export const speakingKeys = [
  "score_speed",
  "score_smoothness",
  "score_slang",
  "score_text_stt_edit",
  "score_speaking",
]

export const listeningKeys = [
  "score_question_listen",
  "score_idle_before_answer",
  "score_listening",
]

export const presentationKeys = [
  "score_head",
  "score_eye",
  "score_body",
  "score_accessory",
  "score_emotion",
  "score_presentation",
]

export const basicCardSettings = {
  category: "Basic Info",
  icon: "clarity:info-standard-solid",
  color: palette.light.grey[500]
}

export const contentCardSettings = {
  category: "Content",
  icon: "ph:article-duotone",
  color: palette.light.error.main
}

export const speakingCardSettings = {
  category: "Speaking",
  icon: "icon-park-twotone:people-speak",
  color: palette.light.warning.main
}

export const listeningCardSettings = {
  category: "Listening",
  icon: "uil:assistive-listening-systems",
  color:  palette.light.success.main,
}

export const presentationCardSettings = {
  category: "Presentation",
  icon: "material-symbols:familiar-face-and-zone",
  color: palette.light.info.main,
}

export const commentCardSettings = {
  category: "Comments",
  icon: "fa-regular:comment-dots",
  color: palette.light.grey[500]
}

export const CATEGORY_FEEDBACK_TABS = {
  content: contentCardSettings,
  speaking: speakingCardSettings,
  listening: listeningCardSettings,
  presentation: presentationCardSettings,
  comment: commentCardSettings,
}

export const proPicColour = [
  palette.light.info.darker,
  palette.light.success.dark,
  palette.light.warning.dark,
  palette.light.error.main
]


//export const TYPE_OPTIONS = [
export const typeOptions = [
  'Background',
  'Situational',
  'Technical',
];

//export const TAGS_OPTION = [
export const tagOptions = [
  'Easy', 
  'Medium', 
  'Hard',
];
//export const KEYWORD_OPTIONS = [
export const keywordOptions = [
  'Web Development',
  'Project Management',
  'Machine Learning',
];

//export const ANSWER_LENGTH_OPTIONS = [
export const answerLengthOptions = [
  { label: '0 second', value: '0' },
  { label: '10 seconds', value: '10' },
  { label: '20 seconds', value: '20' },
  { label: '30 seconds', value: '30' },
  { label: '40 seconds', value: '40' },
  { label: '50 seconds', value: '50' },
  { label: '60 seconds (default)', value: '60' },
  { label: '70 seconds', value: '70' },
  { label: '80 seconds', value: '80' },
  { label: '90 seconds', value: '90' },
  { label: '100 seconds', value: '100' },
  { label: '110 seconds', value: '110' },
  { label: '120 seconds', value: '120' },
  { label: '130 seconds', value: '130' },
  { label: '140 seconds', value: '140' },
  { label: '150 seconds', value: '150' },
  { label: '160 seconds', value: '160' },
  { label: '170 seconds', value: '170' },
  { label: '180 seconds', value: '180' },

];


export const AI_ATTEMPT_COMMENT_ITEMS = ["comment", "good_list", "improvement_list"];
export const AI_ANSWER_COMMENT_ITEMS = ["head_aicomments", "eye_aicomments", "emotion_aicomments",]; 


export const MAX_INTERVIEW_OPTIONS = [
  { label: 'Only 1 time',  value: '1' },
  { label: 'Max. 2 times', value: '2' },
  { label: 'Max. 3 times', value: '3' },
  { label: 'Unlimited', value: '32767' },
];

export const MAX_LISTEN_OPTIONS = [
  { label: 'Only 1 time',  value: '1' },
  { label: 'Max. 2 times', value: '2' },
  { label: 'Max. 3 times', value: '3' },
  { label: 'Unlimited', value: '127' },
];

export const MAX_ANSWER_OPTIONS = [
  { label: 'Only 1 time',  value: '1' },
  { label: 'Max. 2 times', value: '2' },
  { label: 'Max. 3 times', value: '3' },
  { label: 'Unlimited', value: '127' },
];

export const START_ANSWER_OPTIONS = [
  { label: 'No. Interviewee starts answering manually', value: '0' },
  { label: '5 seconds after a question is asked', value: '5' },
  { label: '15 seconds after a question is asked', value: '15' },
  { label: '30 seconds after a question is asked', value: '30' },
  { label: '45 seconds after a question is asked', value: '45' },
  { label: '60 seconds after a question is asked', value: '60' },
];