// hooks
import useAuth from '../hooks/useAuth';
// utils
import createAvatar from '../utils/createAvatar';
// import { SERVER_ADDRESS } from 'awd-common/lib/config'
//
import Avatar, { Props as AvatarProps } from './Avatar';

// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }: AvatarProps) {
  const { user } = useAuth();

  return (
    (user) ?
    <Avatar
      // src={user.avatar? `${SERVER_ADDRESS}/user/avatar/${user?.avatar}`: ""}
      // alt={user?.display_name}
      // color={createAvatar(user?.display_name).color}
      alt={user?.email}
      color={createAvatar('A').color}
      {...other}
    >
      {/* {createAvatar(user?.display_name).name} */}
      {createAvatar(user?.email).name}
    </Avatar>
    : <></>
  );
}
