import { AxiosRequestConfig } from 'axios';
import { FAST_API_ADDRESS } from 'awd-common/lib/config';

// ===================
// User
// ===================

// Sign Up
export const REGISTER: AxiosRequestConfig = Object.freeze({
  method: 'post',
  url: '/user',
});

export const INEE_SIGNUP: AxiosRequestConfig = Object.freeze({
  method: 'post',
  url: '/interviewees/signup',
});

// Get User By Token
export const GET_USER: AxiosRequestConfig = Object.freeze({
  method: 'get',
  url: '/user',
});

// Get User By Token and Verify
export const GET_USER_AND_SIGNUP: AxiosRequestConfig = Object.freeze({
  method: 'get',
  url: '/user?mode=signup',
});

// ===================
// Auth
// ===================

// Log In
export const LOGIN: AxiosRequestConfig = Object.freeze({
  method: 'post',
  url: '/auth/login',
});

// Resend Email
export const RESEND_EMAIL: AxiosRequestConfig = Object.freeze({
  method: 'post',
  url: '/auth/resend-email',
});

// OTP Verify
export const OTP_VERIFY: AxiosRequestConfig = Object.freeze({
  method: 'post',
  url: '/auth/otp-verify',
});

// Get Azure Token
export const GET_AZURE_TOKEN: AxiosRequestConfig = Object.freeze({
  method: 'post',
  url: '/auth/azure-token',
});

// Logout
// method: 'delete',
// url: '/auth/token',

// Get Token
export const GET_TOKEN: AxiosRequestConfig = Object.freeze({
  method: 'get',
  url: '/auth/token',
});


export const PROVIDE_USER_FEEDBACK:AxiosRequestConfig =
  Object.freeze({
    method: 'post',
    url: '/user/feedbacks'
  });

export const GET_SYS_CONFIG: AxiosRequestConfig = Object.freeze({
  method: 'get',
  url: '/user/sys-config',
});
  

export const DETECT_DOCUMENT = (
  text: string,
  has_feedback: number
): AxiosRequestConfig =>
Object.freeze({
    method: 'post',
    url: '',
    data: JSON.stringify({ text, has_feedback }),
  });

export const UPDATE_PREDICTION = (
  id: number, flag: number
): AxiosRequestConfig =>
  Object.freeze({
    method: 'put',
    url: `/chunk/${id}?flag=${flag}` as unknown as string,
  });

export const CHECK_WHITELIST = (
  email: string
): AxiosRequestConfig => Object.freeze({
  method: 'post',
  url: '/user/check-whitelist',
  data: JSON.stringify({ email }),
});
  