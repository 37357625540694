// @mui
import { styled } from '@mui/material/styles';
import { Box, Link, Container, Typography } from '@mui/material';
// layouts
import LogoOnlyLayout from '../../layouts/LogoOnlyLayout';
// components
import Page from '../../components/Page';
// sections
import { useLocation, useNavigate } from 'react-router';
import { enqueueSnackbar } from 'notistack';
import { SentIcon } from '../../assets';
import { VerifyCodeForm } from '../../sections/auth/verify-code';
import useAuth from '../../hooks/useAuth';
import { post } from '../../utils/axios';
import { RESEND_EMAIL } from '../../constants/requests';
import { useState } from 'react';
import { clsx } from 'clsx';

// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

const StyledLink = styled(Link)(
  ({ theme }) => `
  &:hover{
    cursor: pointer;
  }
  &.disabled {
    pointer-events: none;
    cursor: default;
    color: ${theme.palette.grey[500]}
  }
`
);

// ----------------------------------------------------------------------

/**
 * TODO: count down (60s)
 * Real call API
 */
export default function EmailSent() {
  const [loading, setLoading] = useState(false);
  const { state } = useLocation();
  const { otpVerify } = useAuth();
  const navigate = useNavigate();

  const resendEmail = async () => {
    const email = state?.email;
    try {
      if (!email) throw new Error();
      setLoading(true);
      await post(RESEND_EMAIL, { email });
      enqueueSnackbar({
        message: `Resend to your email (${email})`,
        variant: 'success',
      });
      setLoading(false);
    } catch (e) {
      enqueueSnackbar({
        message: 'Failed to resend email',
        variant: 'error',
      });
    }
  };

  const handleSubmit = async (otp: string) => {
    const success = await otpVerify(state?.email, otp);
    if (success) navigate("/");
    else navigate('/token-error', { replace: true });
  };

  return (
    <Page title="Verify Code">
      <LogoOnlyLayout />

      <Container>
        <ContentStyle sx={{ textAlign: 'center' }}>
          <SentIcon sx={{ mb: 5, mx: 'auto', height: 120 }} />
          <Typography variant="h3" paragraph>
            Please check your email!
          </Typography>

          <Typography sx={{ color: 'text.secondary' }}>
            {`We have emailed a verification link to ${
              state?.email || ''
            }, please enter the code in below box to verify your account.`}
          </Typography>

          <Box sx={{ mt: 5, mb: 3 }}>
            <VerifyCodeForm onSubmit={handleSubmit} />
          </Box>

          <Typography variant="body2">
            Don’t have a code? &nbsp;
            <StyledLink
              className={clsx(loading && 'disabled')}
              variant="subtitle2"
              onClick={resendEmail}
            >
              Resend code
            </StyledLink>
          </Typography>
        </ContentStyle>
      </Container>
    </Page>
  );
}
