"use strict";
// server addr & port
// export const SERVER_DOMAIN_NAME = 'http://localhost';
// export const SERVER_PORT = 5051;
Object.defineProperty(exports, "__esModule", { value: true });
exports.FAST_API_ADDRESS = exports.FRONTEND_ADDRESS = exports.FRONTEND_DOMAIN_NAME = exports.FRONTEND_PROTOCOL = exports.SERVER_ADDRESS = exports.SERVER_DOMAIN_NAME = exports.SERVER_PROTOCOL = exports.SERVER_PORT = void 0;
//export const SERVER_ADDRESS = `${SERVER_DOMAIN_NAME}:${SERVER_PORT}`;
exports.SERVER_PORT = 5051;
exports.SERVER_PROTOCOL = 'https';
exports.SERVER_DOMAIN_NAME = 'awd.veriguide.org/awd-api';
exports.SERVER_ADDRESS = `${exports.SERVER_PROTOCOL}://${exports.SERVER_DOMAIN_NAME}`;
// frontend
exports.FRONTEND_PROTOCOL = 'https';
exports.FRONTEND_DOMAIN_NAME = 'awd.veriguide.org';
exports.FRONTEND_ADDRESS = `${exports.FRONTEND_PROTOCOL}://${exports.FRONTEND_DOMAIN_NAME}`;
// local
// export const SERVER_PORT = 5051;
// export const SERVER_PROTOCOL = 'http';
// export const SERVER_DOMAIN_NAME = 'localhost:5051';
// export const SERVER_ADDRESS = `${SERVER_PROTOCOL}://${SERVER_DOMAIN_NAME}`;
// // frontend
// export const FRONTEND_PROTOCOL = 'http';
// export const FRONTEND_DOMAIN_NAME = 'localhost:8080';
// export const FRONTEND_ADDRESS = `${FRONTEND_PROTOCOL}://${FRONTEND_DOMAIN_NAME}`;
// fast api
exports.FAST_API_ADDRESS = 'https://awd.veriguide.org/gpu';
