const createStoreMethods = (storage: Storage) => {
  return {
    removeItem: (key: string) => {
      try {
        storage.removeItem(key);
        return true;
      } catch (e) {
        return false;
      }
    },
    getItem: (key: string) => {
      try {
        const item = storage.getItem(key);
        try {
          return JSON.parse(item);
        } catch (e) {
          return item;
        }
      } catch (e) {
        console.warn(`Loading error:`);
        console.log(e)
        return '';
      }
    },
    setItem: (key: string, value: any) => {
      try {
        return storage.setItem(key, JSON.stringify(value));
      } catch (e) {
        console.warn(`Saving error: ${JSON.stringify(e)}`);
        return '';
      }
    },
    clear: () => {
      try {
        storage.clear();
        return true;
      } catch (e) {
        return false;
      }
    },
  };
};

const { removeItem, getItem, setItem, clear } = createStoreMethods(localStorage);

export { removeItem, getItem, setItem, clear }
