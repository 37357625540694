import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { enqueueSnackbar } from 'notistack';
import { SERVER_AXIOS_CONFIGS, ANALYSIS_AXIOS_CONFIGS, AXIOS_NO_ERROR_BAR_REQS } from '../config';

export const serverAxiosClient = axios.create(SERVER_AXIOS_CONFIGS);

serverAxiosClient.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error: AxiosError<any>) {
    const res = error.response;
    const data = res?.data;
    const message = data?.message || error.message;
    // Mofidy error message
    error.message = message;
    // Display snackbar if not disabled in config
    if (!AXIOS_NO_ERROR_BAR_REQS.has(error.config?.url)) {
      enqueueSnackbar(message, {
        variant: 'error',
      });
    }
    return Promise.reject(error);
  }
);

export const post = (config: AxiosRequestConfig, data: any) =>
  serverAxiosClient({
    ...config,
    data,
  });

export const get = (config: AxiosRequestConfig) => serverAxiosClient(config);
