import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
// components
import LoadingScreen from '../components/LoadingScreen';
import { PATH_NAV } from '../routes/paths';

// ----------------------------------------------------------------------

type GuestGuardProps = {
  children: ReactNode;
};

export default function GuestGuard({ children }: GuestGuardProps) {
  const { isAuthenticated, isInitialized } = useAuth();
  
  if (isAuthenticated) {
    return <Navigate to={PATH_NAV.user.detector} />
  }

  else if (!isInitialized) {
    return <LoadingScreen />;
  }

  else return <>{children}</>;
}
