import jwtDecode from 'jwt-decode';
import { enqueueSnackbar } from 'notistack';
// routes
import { PATH_AUTH } from '../routes/paths';
//
import { serverAxiosClient as axios} from './axios';

// ----------------------------------------------------------------------

const isValidToken = (accessToken: string) => {
  if (!accessToken) {
    return false;
  }
  const decoded = jwtDecode<{ exp: number }>(accessToken);

  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

const isSignedUpInee = (accessToken: string) => {
  const decoded = jwtDecode<{ id?: string }>(accessToken);
  return decoded.id !== undefined;
};

const getSessionId = (accessToken: string) => {
  const decoded = jwtDecode<{ session_id: string }>(accessToken);
  return parseInt(decoded.session_id);
};

const handleTokenExpired = (exp: number) => {
  let expiredTimer;

  const currentTime = Date.now();

  const timeLeft = exp * 1000 - currentTime;
  const hrsLeft = timeLeft / 1000 / 60 / 60;

  // Fix timer overflow, only set logout timer if token expires in 2 hours
  if (hrsLeft > 2) return;

  clearTimeout(expiredTimer);

  expiredTimer = setTimeout(() => {
    enqueueSnackbar({
      message: 'Token expired',
      variant: 'error',
    });

    localStorage.removeItem('accessToken');

    window.location.href = PATH_AUTH.login;
  }, timeLeft);
};

const setSession = (accessToken: string | null) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken || '');
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

    // This function below will handle when token is expired
    const { exp } = jwtDecode<{ exp: number }>(accessToken); // ~3 days by minimals server
    handleTokenExpired(exp);
  } else {
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

export { isValidToken, isSignedUpInee, getSessionId, setSession };
