import { useEffect, useState } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import { Link, Box, Card, Stack, useTheme, Container, Typography } from '@mui/material';
// routes
import { PATH_AUTH, PATH_PAGE } from '../../routes/paths';
// hooks
import useResponsive from '../../hooks/useResponsive';
// components
import Page from '../../components/Page';
import Logo from '../../components/Logo';
import Image from '../../components/Image';
import LoadingScreen from '../../components/LoadingScreen';
// sections
import { LoginForm } from '../../sections/auth/login';

// services
import { getSysConfig } from '../../services/user.service';
// type
import { SysConfig } from '../../@types/detector';

/// temp

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {
  const theme = useTheme();
  const mdUp = useResponsive('up', 'md');
  const [configData, setConfigData] = useState<SysConfig>(null);
  const fetchConfigData = async () => {
    try {
      const config = await getSysConfig();
      setConfigData(config);
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    fetchConfigData();
  }, []);
  if (configData === null) return <LoadingScreen />;
  return (
    <Page title="Login">
      <RootStyle>
        <HeaderStyle>
          <Logo />
        </HeaderStyle>

        {mdUp && (
          <SectionStyle data-cy="login-banner">
            <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
              Hi, Welcome Back
            </Typography>
            <Image
              visibleByDefault
              disabledEffect
              src="/assets/illustrations/illustration_login.png"
              alt="login"
            />
          </SectionStyle>
        )}

        <Container maxWidth="sm">
          <ContentStyle>
            <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
              <Box sx={{ flexGrow: 1 }}>
              <Stack direction={"row"} justifyContent={"flex-start"} alignItems="flex-start" sx={{ mt: 3 }}>
                <Typography variant="h4" gutterBottom>
                  Sign in to AWD
                </Typography>
                <Typography variant='caption'
                    sx={{ transform: "scale(0.75,0.75)" }}
                    color={theme.palette.text.secondary}>
                    Beta
                  </Typography>
                </Stack>
                
                <Typography sx={{ color: 'text.secondary' }}>
                  Enter your email below.
                </Typography>
              </Box>
            </Stack>

            <LoginForm valid_domain={configData.valid_email_domain}/>
            <Typography variant="body2" align="center" sx={{ color: 'text.secondary', mt: 3 }}>
              By logging in, I agree to AWD&nbsp;
              <Link underline="always" color="text.primary" href={PATH_PAGE.terms} target="_blank" rel="noopener noreferrer">
                Terms of Service
              </Link>
              {' '}and{' '}
              <Link underline="always" color="text.primary" href={PATH_PAGE.privacy} target="_blank" rel="noopener noreferrer">
                Privacy Policy
              </Link>
              .
            </Typography>
            <Typography variant="body2" align="center" sx={{ color: 'text.secondary', mt: 3 }}>
              Got questions? Check out our&nbsp;
              <Link underline="always" color="text.primary" href={PATH_PAGE.faqs} target="_blank" rel="noopener noreferrer">
                FAQs
              </Link>
            </Typography>
           
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
