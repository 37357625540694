import { pxToRem, responsiveFontSizes } from '../utils/getFontValue';

// ----------------------------------------------------------------------

const FONT_PRIMARY = 'CircularStd, sans-serif';

const ratio = 1.2
const typography = {
  fontFamily: FONT_PRIMARY,
  fontWeightRegular: 400,
  fontWeightMedium: 600,
  fontWeightBold: 700,

  h1: {
    fontWeight: 700,
    lineHeight: 80 / 64,
    fontSize: pxToRem(40*ratio),
    letterSpacing: 2,
    ...responsiveFontSizes({ sm: 52*ratio, md: 58*ratio, lg: 64*ratio}),
  },
  h2: {
    fontWeight: 700,
    lineHeight: 64 / 48,
    fontSize: pxToRem(32*ratio),
    ...responsiveFontSizes({ sm: 40*ratio, md: 44*ratio, lg: 48*ratio }),
  },
  h3: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(24*ratio),
    ...responsiveFontSizes({ sm: 26*ratio, md: 30*ratio, lg: 32*ratio }),
  },
  h4: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(20*ratio),
    ...responsiveFontSizes({ sm: 20*ratio, md: 24*ratio, lg: 24*ratio }),
  },
  h5: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(18*ratio),
    ...responsiveFontSizes({ sm: 19*ratio, md: 20*ratio, lg: 20*ratio }),
  },
  h6: {
    fontWeight: 700,
    lineHeight: 28 / 18,
    fontSize: pxToRem(17*ratio),
    ...responsiveFontSizes({ sm: 18*ratio, md: 18*ratio, lg: 18*ratio }),
  },
  subtitle1: {
    fontWeight: 600,
    lineHeight: 1.5,
    fontSize: pxToRem(16*ratio),
  },
  subtitle2: {
    fontWeight: 600,
    lineHeight: 22 / 14,
    fontSize: pxToRem(14*ratio),
  },
  body1: {
    lineHeight: 1.5,
    fontSize: pxToRem(17*ratio),
  },
  body2: {
    lineHeight: 22 / 14,
    fontSize: pxToRem(14*ratio),
  },
  caption: {
    lineHeight: 1.5,
    fontSize: pxToRem(12*ratio),
  },
  overline: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(12*ratio),
    textTransform: 'uppercase',
  },
  button: {
    fontWeight: 700,
    lineHeight: 24 / 14,
    fontSize: pxToRem(14*ratio),
    textTransform: 'capitalize',
  },
} as const;

export default typography;
