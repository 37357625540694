import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, BoxProps, Typography, Stack } from '@mui/material';

// ----------------------------------------------------------------------

interface Props extends BoxProps {
  showSingle?: boolean;
  disabledLink?: boolean;
}

export default function Logo({ showSingle = false, disabledLink = false, sx }: Props) {
  const theme = useTheme();
  // const PRIMARY_LIGHT = theme.palette.primary.light;
  // const PRIMARY_MAIN = theme.palette.primary.main;
  // const PRIMARY_DARK = theme.palette.primary.dark;

  const logo_full = (
    <Box sx={{display:'inline-flex', alignItems: "flex-end"}} >
    <Box
      component="img"
      src="/logo/logo_full.png"
      sx={{ 
        // width: 40, 
        height: 40, 
        cursor: 'pointer', ...sx }}
    />
   
    </Box>
  );
  const logo_single = (
    <Box
      component="img"
      src="/logo/logo_single.png"
      sx={{ 
        // width: 40, 
        height: 40, 
        cursor: 'pointer', ...sx }}
    />
  );

  const logo = showSingle? logo_single : logo_full;

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
