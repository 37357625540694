import numeral from 'numeral';

// ----------------------------------------------------------------------

export function fCurrency(number: string | number) {
  return numeral(number).format(Number.isInteger(number) ? '$0,0' : '$0,0.00');
}

export function fPercent(number: number) {
  return numeral(number / 100).format('0.0%');
}

export function fNumber(number: string | number) {
  return numeral(number).format();
}

export function fNumberOneDecimal(number: string | number) {
  if (number === "-") return number;
  return numeral(number).format("0.0");
}

export function fPercentageOneDecimal(number: string ) {
  return `${numeral(number.split("%")[0]).format("0.0")}%`;
}

export function fShortenNumber(number: string | number) {
  return numeral(number).format('0.00a').replace('.00', '');
}

export function fData(number: string | number) {
  return numeral(number).format('0.0 b');
}

export function toPercentage(number: number) {
  return numeral(number).format('0.0%');
}