import { createContext, useEffect, useReducer } from 'react';
// utils
import { serverAxiosClient as axios} from '../utils/axios';
import { isValidToken, setSession } from '../utils/jwt';
import { getItem } from '../utils/storage';
// @types
import { ActionMap, AuthState, AuthUser, JWTContextType } from '../@types/auth';
import {
  GET_USER,
  GET_USER_AND_SIGNUP,
  LOGIN,
  OTP_VERIFY,
  REGISTER,
  INEE_SIGNUP
} from '../constants/requests';
import { FCC } from '../@types/views';

// ----------------------------------------------------------------------

enum Types {
  Initial = 'INITIALIZE',
  Login = 'LOGIN',
  Logout = 'LOGOUT',
  Register = 'REGISTER',
}

type JWTAuthPayload = {
  [Types.Initial]: {
    isAuthenticated: boolean;
    user: AuthUser;
  };
  [Types.Login]: {
    user: AuthUser;
  };
  [Types.Logout]: undefined;
  [Types.Register]: {
    user: AuthUser;
  };
};

export type JWTActions =
  ActionMap<JWTAuthPayload>[keyof ActionMap<JWTAuthPayload>];

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const JWTReducer = (state: AuthState, action: JWTActions) => {
  switch (action.type) {
    case 'INITIALIZE':
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user,
      };
    case 'LOGIN':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };
    case 'LOGOUT':
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };

    case 'REGISTER':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };

    default:
      return state;
  }
};

const AuthContext = createContext<JWTContextType | null>(null);

// ----------------------------------------------------------------------

const AuthProvider: FCC = ({ children }) => {
  const [state, dispatch] = useReducer(JWTReducer, initialState);

  useEffect(() => {
    initialize();
  }, []);

  const initialize = async (
    token?: string,
    isSignUp?: boolean
  ): Promise<boolean> => {
    try {
      const accessToken = token || getItem('accessToken');

      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);

        const response = await axios(isSignUp ? GET_USER_AND_SIGNUP : GET_USER);
        const { user } = response.data;

        dispatch({
          type: Types.Initial,
          payload: {
            isAuthenticated: true,
            user,
          },
        });
        return true;
      } else {
        dispatch({
          type: Types.Initial,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    } catch (err) {
      console.error(err);
      setSession(null);
      dispatch({
        type: Types.Initial,
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
      return false;
    }
  };

  const login = async (email: string) => {
    await axios({
      ...LOGIN,
      data: {
        email,
      },
    });
  };

  const otpVerify = async (email: string, otp: string) => {
    try {
      const res = await axios({
        ...OTP_VERIFY,
        data: {
          email,
          otp,
        },
      });
      const { token, user } = res.data;
      // auto login upon success verify
      if (isValidToken(token) && user.email) {
        setSession(token);
        dispatch({
          type: Types.Initial,
          payload: {
            isAuthenticated: true,
            user,
          },
        });
        return true;
      }
      return false;
    } catch (e) {
      return false;
    }
  };

  const register = async (email: string, display_name: string, lang: string ) => {
    await axios({
      ...REGISTER,
      data: {
        email,
        display_name,
        lang
      },
    });
    // jump to veri email sent (w/ resend btn)
  };

  const direct_signup = async (display_name: string, token?: string) => {
    try {
      setSession(token);
      const res = await axios({
        ...INEE_SIGNUP,
        data: { display_name }
      })
      const user: AuthUser = res.data.user;
      const accessToken: string = res.data.token;
      dispatch({
        type: Types.Initial,
        payload: {
          isAuthenticated: true,
          user
        },
      });
      setSession(accessToken);
      return true;
    }
    catch(e) {
      setSession(null);
      return false;
    }
  };

  const logout = async () => {
    setSession(null);
    dispatch({ type: Types.Logout });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        register,
        direct_signup,
        initialize,
        otpVerify,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
