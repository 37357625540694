// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
// @mui
import { Stack, Alert } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// routes
// hooks
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
// components
import { FormProvider, RHFTextField } from '../../../components/hook-form';
import { LogInData } from '../../../@types/dtos';
import { useNavigate } from 'react-router';
import { useState } from 'react';
// ----------------------------------------------------------------------
import { checkWhitelist } from '../../../services/user.service';

type FormValuesProps = {
  email: string;
  afterSubmit?: string;
};

type Props = {
  valid_domain: string[];
};

export default function LoginForm({ valid_domain }: Props) {
  const { login } = useAuth();
  const navigate = useNavigate();

  const isMountedRef = useIsMountedRef();

  const domainValidation = async (value: string) => {
    if (valid_domain.includes(value.split('@')[1])) {
      return {
        isValid: true,
      }
    }
    
    const isWhitelisted = await checkWhitelist(value);
    if (isWhitelisted) {
      return {
        isValid: true,
      };
    }
    return {
      isValid: false,
      errorMessage: `Invalid email domain: ${value.split('@')[1]}. This service is currently available only to invited users.`,
      // errorMessage: `Invalid email domain: ${value.split('@')[1]}. Only the following are allowed: ${valid_domain.join(', ')} .`,
    }
  }

  const schema = yup.object().shape({
    email: yup.string()
      .required('Email is required.')
      .email('Invalid email format.')
      .test(
        'domain', 
        'Invalid domain', 
        async function (value) {
          const validation = await domainValidation(value);
          if (!validation.isValid) {
            return this.createError({
              path: this.path,
              message: validation.errorMessage,
            });
          }
          return true;
        }
      ),
  });

  const defaultValues = {
    email: '',
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const onSubmit = async (data: FormValuesProps) => {
    try {
      await login(data.email);
      // if no error, then jump to email sent
      navigate('/email-sent', {
        replace: true,
        state: {
          email: data.email,
        },
      });
    } catch (error) {
      console.error(error);

      reset();

      if (isMountedRef.current) {
        setError('afterSubmit', { ...error, message: error.message });
      }
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        {!!errors.afterSubmit && (
          <Alert severity="error" data-cy="form-error">
            {errors.afterSubmit.message}
          </Alert>
        )}
        <RHFTextField name="email" label="Email address" />
      </Stack>

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ my: 2 }}
      ></Stack>

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmitting}
      >
        Login
      </LoadingButton>
    </FormProvider>
  );
}
