import { FC } from 'react';
import './App.css';
import ThemeSettings from './components/settings';
import Router from './routes';
import StoreProvider from './stores';
import ThemeProvider from './theme';
import { ChartStyle } from './components/chart';
import ScrollToTop from './components/ScrollToTop';
import { ProgressBarStyle } from './components/ProgressBar';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
import { SnackbarProvider } from 'notistack';
// import UserFeedbackDrawer from './components/user-feedback';

const App: FC = () => {
  return (
    <StoreProvider>
      <MotionLazyContainer>
        <ThemeProvider>
          <ThemeSettings>
            <SnackbarProvider
              autoHideDuration={5000}
              anchorOrigin={{
                horizontal: 'center',
                vertical: 'bottom',
              }}
            />
            <ProgressBarStyle />
            <ChartStyle />
            <ScrollToTop />
            {/* <UserFeedbackDrawer /> */}
            <Router />
          </ThemeSettings>
        </ThemeProvider>
      </MotionLazyContainer>
    </StoreProvider>
  );
};

export default App;
