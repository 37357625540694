import { serverAxiosClient as axios} from '../utils/axios';

import { GET_SYS_CONFIG } from '../constants/requests'

import { CHECK_WHITELIST } from '../constants/requests'
// utils
import { getItem } from '../utils/storage'


axios.defaults.headers.common.Authorization = `Bearer ${getItem("accessToken")}`

async function getSysConfig() {
  const res = await axios(GET_SYS_CONFIG);
  const data = res.data;
  let config = {
    ...data,
    valid_email_domain: data.valid_email_domain.split(','),
  }
  return config;
}

async function checkWhitelist(email: string) {
  try {
    const res = await axios(CHECK_WHITELIST(email));
    return res.data.isWhitelisted; // Assuming the server returns a boolean under "isWhitelisted"
  } catch (error) {
    console.error('Failed to check whitelist:', error);
    return false; // Or you could throw an error to handle it elsewhere
  }
}
export { 
  getSysConfig,
  checkWhitelist
};
