import { createContext, useEffect } from 'react';

import { FCC } from '../@types/views';
import ViewStore from './ViewStore';

export const viewStore = new ViewStore();
export const ViewContext = createContext<ViewStore>(null as any);

const StoreProvider: FCC = ({ children }) => {
  useEffect(() => {
    init();
  }, []);
  const init = () => {
    // init code
  };
  return (
    <ViewContext.Provider value={viewStore}>{children}</ViewContext.Provider>
  );
};

export default StoreProvider;
