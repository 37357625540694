// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '';
const ROOTS_DASHBOARD = '/dashboard';
const ROOTS_NAV = '';
const ROOTS_USER = '/user';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  directSignUp: (token) => path(ROOTS_AUTH, `/direct-signup?token=${token}`) , 
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
};

export const PATH_PAGE = {
  maintenance: '/maintenance',
  terms: '/terms',
  privacy: '/privacy',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
  faqs: '/faqs',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
};

export const PATH_NAV = {
  root: ROOTS_NAV,
  user: {
    root: path(ROOTS_USER, ''),
    detector: path(ROOTS_USER, '/detector'),
    result: path(ROOTS_USER, '/result'),
  },
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
